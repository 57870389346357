<template>
  <div v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" v-if="requisition.hub_transfer_chalan_form_picture">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="requisition.hub_transfer_chalan_form_picture_url" target="_blank" download>
                <img :src="requisition.hub_transfer_chalan_form_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
                <div class="chalan-form-pictre-footer">Figure: Hub Received Products Chalan Form</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Unit</th>
              <th>Transfer Qty</th>
              <th>Accept <span style="color: red">|Dispute Qty</span></th>
              <th>Transfer Comment</th>
              <th>Hub Comment</th>
              <th>Transfer Date</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transfer, index) in transfers" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ transfer.product.name }}</td>
              <td>{{ transfer.product.unit }}</td>
              <td>{{ transfer.transfer_amount }}</td>
              <td>{{ remainingAmount(transfer.transfer_amount, transfer.dispute_amount)}} <span style="color: red">| {{ transfer.dispute_amount || 0 }}</span></td>
              <td>{{ transfer.transferer_comment }}</td>
              <td>{{ transfer.hub_comment }}</td>
              <td>{{ customDate(transfer.created_at) }}</td>
              <td>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Pending'">Pending</a-tag>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Rejected'">Rejected</a-tag>
                <a-tag color="#87d068" v-if="transfer.transfer_status == 'Accepted'">Accepted</a-tag>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Partial'">Partial</a-tag>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="transfers.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'List',
  components: { Empty, requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      transfers: {},
      requisition: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getTransferRequisitionItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getTransferRequisitionItems() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/requisition-previous-transfer/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.transfers = data.transfers
            this.requisition = data.requisition
            this.current = data.max_item_requisition_status
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    remainingAmount(transferAmount, disputeAmount) {
      return parseFloat(transferAmount) - parseFloat(disputeAmount | 0)
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
</style>
